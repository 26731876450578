<template>
  <div class="container containerXinke">
    <div class="header">
      <div class="wrap clearfloat">
        <div class="headerLogo fl">
          <a :href="`/${apiName}`"><img src="../assets/img/logo_sgbd.jpg" alt=""></a>
        </div>
        <!-- <div class="headerNav fr">
          <ul class="headerNavList clearfloat">
            <li :class="{active: active == index}" v-for="(item, index) in navList" :key="index">
              <span class="headerNavTxt trans" v-html="item.title" @click="haddleNav(index, item)"></span>
              <div class="line"></div>
            </li>
          </ul>
        </div> -->
        <div class="nav_box fr">
          <ul class="clearfloat">
            <li :class="{'active' : active == 0}">
              <div class="nav_grop">
                <h2><router-link to="/">网站首页</router-link></h2>
              </div>
            <li v-for="(item, index) in navListN" :key="index" :class="{'active':active == item.id}">
              <div
                class="nav_grop" 
                @mouseenter="navShow(item.id)"
                @mouseleave="navClose()">
                <h2><a :href="item.url">{{item.name}}</a></h2>
                
                  <div class="nav_down">
                    <transition name="el-fade-in-linear">
                    <div class="nav_down_wrap clearfloat" v-show="navDown == item.id && item.group_list.length != 0">
                      <p v-for="(item1, index1) in item.group_list" :key="index1">
                        <a :href="item1.url && item1.url != '' ? `${item1.url}` : `${apiName}/list?id=${item1.id}&navName=${item.name}&groupName=${item1.name}`" v-if="item1.have_list == 0 && item1.is_link !== 1">{{item1.name}}</a>
                        <a :href="item1.url && item1.url != '' ? `${item1.url}` : `${apiName}/detail?id=${item1.id}&navName=${item.name}&groupName=${item1.name}&noList=1`" v-if="item1.have_list == 1 && item1.is_link !== 1">{{item1.name}}</a>
                        <a :href="item1.url" target="_blank" v-if="item.name == '核心成员'">{{item1.name}}</a>
                        <a :href="item1.link_url" target="_blank" v-if="item1.is_link === 1">{{item1.name}}</a>
                      </p>
                    </div>
                    </transition>
                  </div>
                
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="footerWrap">
        <div class="wrap">
          <el-row :gutter="30">
            <el-col :span="9">
              <div class="footerLeft">
                <div class="footerTitle">
                  <div class="name">网站导航</div>
                  <div class="line"></div>
                </div>
                <div class="footerCont">
                  <span class="footerLeftTxt"><router-link to="/">网站首页</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="/listPage">关于我们</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="/list">新闻中心</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="/business">核心业务</router-link></span> <br>
                  <span class="footerLeftTxt"><router-link to="/competence">产品中心</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="/party">合作案例</router-link></span> <span class="footerLeftLine">|</span>
                  <span class="footerLeftTxt"><router-link to="/job">招贤纳士</router-link></span>
                </div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="footerCenter">
                <div class="footerTitle">
                  <div class="name">联系我们</div>
                  <div class="line"></div>
                </div>
                <div class="footerCont footerCenterCont clearfloat">
                  <div class="footerCenterInner footerCenterInnerF fl">
                    <div class="footerCenterIco">
                      <img src="../assets/img/footer_ico_tel.png" alt="">
                    </div>
                    <div class="footerCenterTxt">
                      <h3>0931-4170777</h3>
                      <p>周一至周日 8:30-12:00 ，14:00-18:00</p>
                    </div>
                  </div>
                  <div class="footerCenterInner fl">
                    <div class="footerCenterIco">
                      <img src="../assets/img/footer_ico_add.png" alt="">
                    </div>
                    <div class="footerCenterTxt">
                      <h3>甘肃省兰州市七里河区南滨河中路405号308室</h3>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="footerRight">
                <div class="footerTitle">
                  <div class="name">关注我们</div>
                  <div class="line"></div>
                </div>
                <div class="footerCont">
                  <div class="footerRightCont">
                    <img src="../assets/img/ewm_gzh.png" alt="">
                    <p>扫码关注我们</p>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="footerBotttom">
        版权所有：©甘肃赛格车联网智能科技有限公司 &nbsp;&nbsp; 备案编号：陇ICP备18000926号-2 &nbsp;&nbsp; 甘公网安备：62010302001287号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data(){
    return {
      active: 0,
      navListN: [
        {
            "id":1,
            "name":"关于我们",
            "isIndex":0,
            "url": `${this.apiName}/listPage`,
            "group_list":[
                {
                    "id":1,
                    "name":"企业简介",
                    "url": `${this.apiName}/listPage`,
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:13.037Z"
                },
                {
                    "id":2,
                    "name":"企业资质",
                    "url": `${this.apiName}/listPage?t=1`,
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:13.037Z"
                },
                {
                    "id":3,
                    "name":"品牌介绍",
                    "url": `${this.apiName}/listPage?t=2`,
                    "nav_id":2,
                    "have_list":0,
                    "create_time":"2020-12-22T07:10:23.364Z"
                },
                {
                    "id":4,
                    "name":"联系我们",
                    "url": `${this.apiName}/listPage?t=3`,
                    "nav_id":2,
                    "have_list":1,
                    "create_time":"2020-12-22T07:10:41.071Z",
                },
            ]
        },
        {
            "id":4,
            "name":"核心业务",
            "isIndex":0,
            "url": `${this.apiName}/business`,
            "group_list":[
                {
                    "id":14,
                    "name":"定位业务",
                    "url": `${this.apiName}/business`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z"
                },
                {
                    "id":19,
                    "name":"主动安全",
                    "url": `${this.apiName}/business?t=1`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:25.749Z"
                },
                {
                    "id":15,
                    "name":"两客一危",
                    "url": `${this.apiName}/business?t=2`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z"
                },
                {
                    "id":15,
                    "name":"网约出租",
                    "url": `${this.apiName}/business?t=3`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z"
                },
                {
                    "id":16,
                    "name":"智慧公交",
                    "url": `${this.apiName}/business?t=4`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:18.405Z"
                },
                {
                    "id":17,
                    "name":"定制开发",
                    "url": `${this.apiName}/business?t=5`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:25.749Z"
                },
                // {
                //     "id":19,
                //     "name":"汽车前装",
                //     "url": `${this.apiName}/business?t=6`,
                //     "nav_id":4,
                //     "have_list":0,
                //     "create_time":"2020-12-22T07:13:25.749Z"
                // },
                // {
                //     "id":18,
                //     "name":"智慧农业",
                //     "url": `${this.apiName}/business?t=7`,
                //     "nav_id":4,
                //     "have_list":0,
                //     "create_time":"2020-12-22T07:13:25.749Z"
                // },
            ]
        },
        {
            "id":7,
            "name":"产品中心",
            "isIndex":0,
            "url": `${this.apiName}/competence`,
            "group_list":[
                {
                    "id":14,
                    "name":"监控中心",
                    "url": `${this.apiName}/competence`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:12:55.466Z"
                },
                {
                    "id":15,
                    "name":"部标产品",
                    "url": `${this.apiName}/competence?t=1`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:02.484Z"
                },
                {
                    "id":16,
                    "name":"主动安全",
                    "url": `${this.apiName}/competence?t=2`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:18.405Z"
                },
                {
                    "id":17,
                    "name":"简易终端",
                    "url": `${this.apiName}/competence?t=3`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:25.749Z"
                },
                {
                    "id":17,
                    "name":"网约出租",
                    "url": `${this.apiName}/competence?t=4`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:25.749Z"
                },
                {
                    "id":17,
                    "name":"定制开发",
                    "url": `${this.apiName}/competence?t=5`,
                    "nav_id":4,
                    "have_list":0,
                    "create_time":"2020-12-22T07:13:25.749Z"
                },
            ]
        },
        {
            "id":2,
            "name":"新闻中心",
            "isIndex":0,
            "url": `${this.apiName}/list`,
            "group_list":[
                {
                    "id":7,
                    "name":"公司动态",
                    "url": `${this.apiName}/list`,
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:14.391Z"
                },
                {
                    "id":9,
                    "name":"行业动态",
                    "url": `${this.apiName}/list?t=1`,
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z"
                },
                {
                    "id":9,
                    "name":"中标公告",
                    "url": `${this.apiName}/list?t=2`,
                    "nav_id":3,
                    "have_list":0,
                    "create_time":"2020-12-22T07:11:41.023Z"
                },
            ]
        },
        {
            "id":3,
            "name":"合作案例",
            "isIndex":0,
            "url": `${this.apiName}/party`,
            "group_list":[
            ]
        },
        {
            "id":5,
            "name":"招贤纳士",
            "isIndex":0,
            "url": `${this.apiName}/job`,
            "group_list":[]
        },
        // {
        //     "id":6,
        //     "name":"学习园地",
        //     "isIndex":0,
        //     "url": `/${this.apiName}/learning`,
        //     "group_list":[

        //     ]
        // }
      ],
      navDown: 0,

    }
  },
  mounted(){
    this.getPath()
  },
  created(){

  },
  watch:{
    '$route':'getPath'
  },
  methods: {
    // 二级导航展开
    navShow(d){
      let _this = this
      // _this[d] = true
      _this.navDown = d
    },
    // 二级导航收起
    navClose(){
      let _this = this
      // _this[d] = false
      _this.navDown = 0
    },
    haddleNav(index, params){
      var _this = this

      _this.active = index
      _this.$router.push(params.url)
    },
    getPath(){
      var _this = this

      _this.active = _this.$route.meta.index
    }
  }
}
</script>
<style>
.header {
  padding-bottom: 12px;
  padding-top: 18px;
  text-align: center;
  /* background-image: url(../assets/img/header_bg.png); */
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: rgba(13,30,46,.8); */
  background-color: rgba(25, 112, 185, 0.8);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 22;
}
.headerLogo {
  margin-top: 6px;
  /* margin-bottom: 20px; */
}
.headerLogo img {
  /* width: 360px; */
  height: 46px;
  width: auto;
  vertical-align: middle;
}
.headerNavList {
  padding-bottom: 18px;
}
.headerNavList li {
  float: left;
  text-align: center;
  width: 12.5%;
  position: relative;
  line-height: 20px;
  font-size: 16px;
}
.headerNavList li:last-child .line {
  display: none;
}
.headerNavTxt {
  display: inline-block;
  min-width: 45px;
  color: #000;
  letter-spacing: 4px;
  cursor: pointer;
}
.headerNavTxt:hover {
  color: #BC8F68;
}
.headerNavList .line {
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: #d3d3d3;
  top: 50%;
  margin-top: -1px;
  right: -6px;
}
.headerNavList li.active .headerNavTxt {
  font-weight: bold;
  color: #BC8F68;
}
.headerNavList li.active::after {
  display: block;
  width: 46px;
  height: 18px;
  content: "";
  position: absolute;
  bottom: -18px;
  left: 50%;
  margin-left: -23px;
  background-image: url(../assets/img/header_nav_ico.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.main {
  min-height: 500px;
}
.footer {
  background: #282E31;
  color: #9D9D9D;
}
.footerWrap {
  padding: 60px 0 40px;
  text-align: left;
}
.footerTitle {
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 30px;
}
.footerTitle .line {
  width: 26px;
  height: 2px;
  background: #fff;
  margin-top: 8px;
}
.footerLeftTxt {
  display: inline-block;
  min-width: 56px;
  vertical-align: middle;
}
.footerLeftTxt a {
  color: #9D9D9D;
}
.footerLeftTxt a:hover {
  color: #fff;
}
.footerLeftLine {
  display: inline-block;
  margin: 0 20px;
  vertical-align: middle;
}
.footerLeft .footerCont {
  line-height: 34px;
}
.footerCenterIco {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  margin-right: 4px;
  line-height: 36px;
}
.footerCenterIco img {
  width: 90%;
  height: auto;
  vertical-align: middle;
}
.footerCenterTxt {
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
}
.footerCenterTxt h3 {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
}
.footerCenterTxt p {
  font-size: 14px;
}
.footerCenterCont {
  padding-top: 6px;
}
.footerCenterInnerF {
  margin-right: 10px;
  margin-bottom: 10px;
}
.footerRightCont {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.footerRightCont img {
  width: 90px;
  height: auto;
  margin-top: -6px;
}
.footerRightCont img:first-child {
  margin-right: 10px;
}
.footerRightCont p {
  font-size: 12px;
  color: #BFC1C2;
  margin-top: 10px;
}
.footerBotttom {
  font-size: 12px;
  color: #9D9D9D;
  line-height: 48px;
  border-top: 1px solid #9D9D9D;
}
.nav_box {
    /* height: 38px; */
    /* background-color: #1970b9; */
    line-height: 38px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0 10px;
  }
  .nav_box ul li {
    height: 60px;
    line-height: 60px;
    float: left; 
    width: 96px; 
    color: #fff;
    text-align: center;
    position: relative;
  }
  /* .nav_box ul li a {
    display: block;
  } */
  .nav_grop {
    /* width: 82px; */
    height: 100%;
    /* margin: 5px auto 0; */
    position: relative;
  }
  .nav_box ul li h2 {
    text-align: center;
    /* line-height: 50px; */
    height: 100%;
    font-size: 18px;
    border-radius: 4px;
    /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
    overflow: hidden;
    /* border: 1px solid #1970b9; */
  }
  .nav_box ul li h2 a {
    display: block;
    color: #fff;
  }
  .nav_grop:hover h2 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .nav_grop:hover h2 a {
    color: #F0822B;
    /* background-color: #fff; */
    font-weight: 600;
  }
  .nav_box ul li.active h2 a {
    color: #F0822B;
    /* background-color: #ff8c00; */
    font-weight: 600;
  }
  .nav_down {
    position: absolute;
    width: 104px;
    left: -1px;
    top: 60px;
    z-index: 55;
  }
  .nav_down_wrap {
    padding: 10px 0;
    /* border: 1px solid rgb(35, 96, 176); */
    border-top: none;
    /* background-color: #fff; */
    background-color: rgba(25, 112, 185, 0.9);
  }
  .nav_down p {
    line-height: 36px;
    text-align: center;
    font-size: 16px;
  }
  .nav_down p a {
    color: #fff;
  }
  .nav_down p a:hover {
    color: #F0822B;
    text-decoration: underline;
  }
  .header_hd {
    height: 34px;
    line-height: 34px;
    /* background-image: url("../assets/img/st_03.jpg"); */
    background-repeat: repeat-x;
    background-size: auto 100%;
    padding: 0 25px;
    font-size: 12px;
  }
  .header_top {
    padding: 10px;
  }
  .mobile {
    display: block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 600;
  }

  .mobile:hover {
    color: #d60f0f;
  }
  .user_input {
    display: inline-block;
    width: 120px;
  }
  .user_l {
    display: inline-block;
    margin-left: 10px;
  }
  .user_sure {
    margin: 0 4px 0 6px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border: 1px solid #b6b6b6;
    cursor: pointer;
    vertical-align: top;
    margin-top: 3px;
    background-color: #e9e9e9;
    border-radius: 2px;
  }
  .user_sure:hover {
    background-color: #dddddd;
  }
  .user_forget:hover {
    color: #d60f0f;
    text-decoration: underline;
  }
  .mail_dsz {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #dedede;
    margin-top: 6px;
    font-size: 12px;
    color: #d60f0f;
    margin-left: 30px;
  }
  .mail_dsz:hover {
    border-color: #d60f0f;
  }
  .user_wrap {
    display: inline-block;
  }
  .footerBotttom {
    text-align: center;
  }
  .containerXinke .header {
    background-color: #fff;
    color: #000;
    border-top: 8px solid #003E92;
    padding-bottom: 0;
    padding-top: 0;
  }
  .containerXinke .nav_box ul li h2 a {
    color: #000;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 40px auto;
    background-image: none;
    -moz-transition: all 0.6s ease-in;
    -webkit-transition: all 0.6s ease-in;
    -o-transition: all 0.6s ease-in;
    transition: all 0.6s ease-in;
  }
  .containerXinke .nav_box ul li.active h2 a {
    color: #003E92;
  }
  .containerXinke .nav_box ul li {
    height: 100px;
    line-height: 100px;
  }
  .containerXinke .nav_down {
    /* display: none; */
    top: 110px;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .containerXinke .nav_down_wrap {
    background: #fff;
    color: #000;
    border-top: 4px solid #003e92;
  }
  .containerXinke .nav_down p a {
    position: relative;
    color: #000;
    left: 0;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .containerXinke .nav_box ul li:hover h2 a {
    background-image: url('../assets/img/menu-link-shape.png');
  }
  .containerXinke .nav_grop:hover h2 a {
    color: #0070c3;
  }
  .containerXinke .nav_down p a:hover {
    color: #0070c3;
    left: 8px;
  }
  .containerXinke .nav_box ul li:hover .nav_down {
    top: 100px;
    /* display: block; */
  }
  .containerXinke .headerLogo {
    margin-top: 10px;
  }
  .containerXinke .headerLogo img {
    height: 80px;
  }
</style>